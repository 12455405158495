#embedded-report-page {
  width: 100%;
  height: 100%;

  .embedded-report-component {
    width: 100%;
    height: 100%;

    iframe {
      height: calc(100% - 4px) !important;
      box-sizing: border-box;
      border: none;
    }
  }
}
