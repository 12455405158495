body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"),
    url("./fonts/Avenir/AvenirLTStd-Black.otf") format("opentype");
}

@font-face {
  font-family: "AvenirBook";
  src: local("AvenirBook"),
    url("./fonts/Avenir/AvenirLTStd-Book.otf") format("opentype");
}

@font-face {
  font-family: "AvenirRoman";
  src: local("AvenirRoman"),
    url("./fonts/Avenir/AvenirLTStd-Roman.otf") format("opentype");
}

@font-face {
  font-family: "Matia";
  src: local("Matia"),
    url("./fonts/Matia/Pointed Gallery.otf") format("opentype");
}
