#portal .topnav {
  a.link {
    color: white;
    text-decoration: none;
    font-weight: 500;
    padding: 0 5px;
    display: flex;
    align-items: center;
    transition: transform 80ms ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}

a.menuitem {
  color: black;
  text-decoration: none;
  width: 100%;
  padding: 8px 14px;
}
