::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #8e8e8e;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f07428;
  border-radius: 8px;
  border: 2px outset #f59e69;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f08645;
  border: 2px outset #f59e69;
}
